<template>
  <div>
    <h2>A部分</h2>
    <draggable
      v-model="listA"
      group="items"
      @start="dragStart"
      @end="dragEnd('A',$event)"
    >
      <div v-for="item in listA" :key="item.id" class="item">
        {{ item.name }}
      </div>
    </draggable>

    <h2>B部分</h2>
    <draggable
      v-model="listB"
      group="items"
      @start="dragStart"
      @end="dragEnd('B',$event)"
    >
      <div v-for="item in listB" :key="item.id" class="item">
        {{ item.name }}
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      listA: [
        { id: 1, name: "Item 1", enable: true },
        { id: 2, name: "Item 2", enable: true },
        { id: 3, name: "Item 3", enable: true },
      ],
      listB: [
        { id: 4, name: "Item 4", enable: false },
        { id: 5, name: "Item 5", enable: false },
        { id: 6, name: "Item 6", enable: false },
      ],
    };
  },
  methods: {
    dragStart(evt) {
      // 在拖动开始时执行的操作
      // console.log("拖动开始", evt);
    },

    dragEnd(section, event) {
    console.log(event); // 检查事件对象的结构
    if (section === 'A' && event && event.newIndex !== undefined) {
     
      this.listA[event.oldIndex].enable = true;
      this.listB[event.newIndex].enable = false;
       // console.log('A')
      // console.log(event.oldIndex)
      // console.log(event.newIndex)
      // console.log(this.listA[event.oldIndex].enable)
      // console.log( this.listB[event.newIndex].enable)
    } else if (section === 'B' && event && event.newIndex !== undefined) {
      this.listA[event.newIndex].enable = true;
      this.listB[event.oldIndex].enable = false;
      // console.log('B')
      // console.log(event.oldIndex)
      // console.log(event.newIndex)
      // console.log(this.listA[event.oldIndex].enable)
      // console.log( this.listB[event.newIndex].enable)
    }
   console.log(this.listA)
   console.log(this.listB)
  },
  },
};
</script>

<style>
.item {
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: move;
}
</style>
